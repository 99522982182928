import * as React from "react";
import Head from "next/head";
import Image from "next/image";

// Import required MUI layout/components
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";

import mainTheme from "./mainTheme";
import heroStyles from '../styles/Hero.module.css';

const HeroIndex = ({copy, ctaText, ctaUrl}) => {
  let whichImage = Math.floor(Math.random() * 5) + 1;
  let heroCoverBack = heroStyles[`heroCover${whichImage}`];

  return (
    <ThemeProvider theme={mainTheme}>
      <div className={heroStyles.heroBackground}>
        <div className={heroStyles.heroCover+' '+heroCoverBack}>
        </div>
        <Container maxWidth="lg">
            <div className={heroStyles.contentContainer}>
              <div className={heroStyles.contentDiv}>
                <div>
                  {copy}
                </div>
                <Button href={ctaUrl} variant="contained" disableElevation color="secondary">
                  {ctaText}
                </Button>
              </div>
            </div>            
            
        </Container>
      </div>
    </ThemeProvider>
  );
};

export default HeroIndex;
