import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";

import mainTheme from "./mainTheme";
import styles from "../styles/Label.module.css"

const Label = ({children}) => {
    return (<ThemeProvider theme={mainTheme}>
        <div className={styles.labelDiv}>{children}</div>
    </ThemeProvider>)
}

export default Label;