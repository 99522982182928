import { useEffect } from "react";
import Head from "next/head";
import Image from "next/image";
import { createClient } from "next-sanity";
import imageUrlBuilder from "@sanity/image-url";
import { getImageDimensions } from "@sanity/asset-utils";

import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

import MainHeader from "../components/MainHeader";
import MainFooter from "../components/MainFooter";
import Headshot from "../components/Headshot";
import HeroIndex from "../components/HeroIndex";
import HeroIndexVid from "../components/HeroIndexVid";
import BlogLatest from "../components/BlogLatest";
import EventRow from "../components/EventRow";
import * as dayjs from "dayjs";

import { getCookie } from "cookies-next";

import fonts from "../styles/FontInter.module.css";
import mainTheme from "../components/mainTheme";
import styles from "../styles/Home.module.css";

import client from "../components/client";

export default function Home({ utm, posts, events }) {
  useEffect(() => {
    dataLayer.push({ event: "optimize.activate" });
  }, []);

  let heroCopy = (
    <div>
      REIMAGINING <br />
      THE{" "}
      <span style={{ color: mainTheme.palette.secondary.main }}>
        REAL ESTATE
        <br />
        JOURNEY
      </span>
      <br />
      <span style={{ fontSize: "16px", fontWeight: "700" }}>people. productivity. profitability.</span>
      <br />
    </div>
  );

  let eventList = [];
  for (let event of events) {
    const now = dayjs();
    const eventDate =
      dayjs(event.event_date).format("MMM D, YYYY") != "Invalid Date" ? dayjs(event.event_date) : dayjs();

    if (event.event_type === "webinar" && eventList.length < 3 && eventDate > now) {
      eventList.push(event);
    }
  }
  if (eventList.length < 3) {
    for (let event of events) {
      if (event.event_type == "ondemand" && eventList.length < 3) {
        eventList.push(event);
      }
    }
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <Head>
        <title>Elm Street | Real Estate Software for Sales & Marketing</title>
        <meta
          name="description"
          content="Generate more leads, repeat business, and referrals with leading real estate marketing software including CRM, IDX websites, lead generation tools, and more."
        />
        <link rel="icon" href="/favicon.ico" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@weareelmstreet" />
        <meta name="twitter:creator" content="@weareelmstreet" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://elmstreet.com" />
        <meta property="og:title" content="Elm Street | Real Estate Software for Sales & Marketing" />
        <meta
          property="og:description"
          content="Generate more leads, repeat business, and referrals with leading real estate marketing software including CRM, IDX websites, lead generation tools, and more."
        />
        <meta property="og:image" content="https://elmstreet.com/og_images/elmstreetog.jpg" />
      </Head>
      <CssBaseline className={fonts} />
      <MainHeader />

      <HeroIndex copy={heroCopy} ctaText={"Connect With Us"} ctaUrl={"/demo"} />

      <Container maxWidth="lg" style={{ top: "-48px", position: "relative" }}>
        <Grid container columnSpacing={6}>
          <Grid item md={6} sm={12}>
            <div
              style={{
                backgroundColor: "#333666",
                color: "#FFFFFF",
                padding: "32px",
              }}
            >
              <Typography variant="h5" component="h2">
                REPEAT &amp; REFERRAL{" "}
              </Typography>
              <div
                style={{
                  minHeight: "96px",
                  fontSize: "14px",
                  marginTop: "16px",
                }}
              >
                It is all about relationships in real estate. Nurturing them. Developing them. Growing your repeat and
                referral client base as a result.
              </div>
              <Button variant="contained" color="info" href="/repeat-and-referral">
                explore
              </Button>
            </div>
          </Grid>
          <Grid item md={6} sm={12}>
            <div style={{ backgroundColor: "#5BC1BA", padding: "32px" }}>
              <Typography variant="h5" component="h2">
                prospecting{" "}
              </Typography>
              <div
                style={{
                  minHeight: "96px",
                  fontSize: "14px",
                  marginTop: "16px",
                }}
              >
                Boost your lead generation and digital marketing with our lead prospecting solutions.
              </div>
              <Button variant="contained" color="info" style={{ color: "#1d1746" }} href="/prospecting">
                Take a Tour
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>

      {/*
      <Container maxWidth="lg" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <Container
          maxWidth="lg"
          style={{
            paddingBottom: "24px",
          }}
        >
          <div
            style={{
              marginTop: "35px",
              marginBottom: "30px",
              textAlign: "center",
            }}
          >
            <Grid container spacing={8}>
              <Grid item md={12} sm={12}>
                <Typography variant="h4" component="h5">
                  <span style={{ color: "#f69c26" }}>special limited time</span> offers
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Container>
      </Container>



      <div>
        <Container
          maxWidth="lg"
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingBottom: "35px",
          }}
        >
          <Grid container columnSpacing={16}>
            <Grid item md={6} sm={12} style={{ paddingBottom: "33px" }}>
              <div
                style={{
                  border: "solid",
                  borderColor: "#f7f7f7",
                  borderWidth: "3px",
                  paddingBottom: "20px",
                }}
              >


                <div
                  style={{
                    backgroundColor: "#ffffff",
                    textAlign: "center",
                    padding: "25px",
                  }}
                >
                  <div style={{ paddingTop: "13px", paddingBottom:"13px" }}>
                    <Image src="/ixact.svg" width="305px" height="60px" alt="IXACTContact&reg; by Elm Street" />
                  </div>
                  Focused on networking and marketing to your existing network?  See why over 14,000 Brokers &amp; Agents rely on IXACT's powerful all-in-one CRM.

                  <div style={{ paddingTop: "20px" }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      href="https://signup.ixactcontact.com/?pc=GET60"
                      target="_blank"
                    >
                      60-day free trial
                    </Button>

                    <div style={{ paddingTop: "10px" }}>
                      <Button variant="contained" color="secondary" href="/crm#form" target="_blank">
                        schedule a demo
                      </Button>
                    </div>
                  </div>
                  <span style={{fontSize:"10px",}}>*Free Trial - New Accounts Only.</span>
                </div>

              </div>
            </Grid>




            <Grid item md={6} sm={12} style={{ paddingBottom: "33px" }}>
              <div
                style={{
                  border: "solid",
                  borderColor: "#f7f7f7",
                  borderWidth: "3px",
                  paddingBottom: "20px",
                }}
              >
           

                <div
                  style={{
                    backgroundColor: "#ffffff",
                    textAlign: "center",
                    padding: "25px",
                  }}
                >
                  <div style={{ paddingTop: "13px", paddingBottom:"13px" }}>
                    <Image src="/elevate.svg" width="170px" height="60px" alt="Elevate CRM&reg; by Elm Street" />
                  </div>
                  Love prospecting and nurturing new relationships? Try the #1 CRM for social media lead generation and done-for-you marketing.
                  <div style={{ paddingTop: "20px" }}>
                    <Button variant="contained" color="secondary" href="https://tryelevate.com/freetrial/" target="_blank">
                      60-Day Free Trial
                    </Button>

                    <div style={{ paddingTop: "10px" }}>
                      <Button variant="contained" color="secondary" href="/crm#form" target="_blank">
                        schedule a demo
                      </Button>
                    </div>
                    <span style={{fontSize:"10px",}}>*Free Trial - New Accounts Only.</span>
                  </div>
                </div>

              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
                */}

      <Container maxWidth="lg" style={{ paddingTop: "30px", paddingBottom: "65px" }}>
        <div style={{ paddingBottom: "30px" }}>
          <Typography variant="h5" component="h2">
            <span style={{ color: mainTheme.palette.secondary.main }}>#WeAreElmStreet</span>
          </Typography>
          <strong>Innovative Technology. Marketing Experts. Dedicated Support.</strong>
        </div>

        <Grid container columnSpacing={11}>
          <Grid item md={3} sm={6} xs={12} style={{ paddingBottom: "24px", textAlign: "center" }}>
            <Headshot src="/est_index2.jpg" name="" title="" alt="Build your Brand with Elm Street" />
            <br />
            <div style={{ marginTop: "-20px" }}>
              <strong>
                AGENTS
                <br />
                &amp; TEAMS
              </strong>
            </div>
            Market your listings across multiple platforms, including IDX websites and social media. Get repeat and
            referral business.
            <div style={{ paddingTop: "12px" }}>
              <Button variant="contained" color="secondary" href="/agent-solutions">
                explore
              </Button>
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12} style={{ paddingBottom: "24px", textAlign: "center" }}>
            <Headshot src="/est_index1.jpg" name="" title="" alt="Fill Your Pipeline with Elm Street" />
            <br />
            <div style={{ marginTop: "-20px" }}>
              <strong>
                BROKER &amp; OWNER
                <br />
                SOLUTIONS
              </strong>
            </div>
            Managing agents and clients is easier with our broker solutions. Scalable, affordable, and simple, our
            platform suits your needs.
            <div style={{ paddingTop: "12px" }}>
              <Button variant="contained" color="secondary" href="/broker-solutions">
                explore
              </Button>
            </div>
          </Grid>

          <Grid item md={3} sm={6} xs={12} style={{ paddingBottom: "24px", textAlign: "center" }}>
            <Headshot src="/est_index3.jpg" name="" title="" alt="Engage Your Audience with Elm Street" />
            <br />
            <div style={{ marginTop: "-20px" }}>
              <strong>PARTNERS PROGRAMS FOR DEVELOPERS</strong>
            </div>
            Get access to incentives, resources, innovative technology, and other services for developers &amp;
            potential partners.
            <div style={{ paddingTop: "12px" }}>
              <Button variant="contained" color="secondary" href="/developer-partners">
                explore
              </Button>
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12} style={{ paddingBottom: "24px", textAlign: "center" }}>
            <Image
              src="/est_index4.jpg"
              alt={`Nurture Done For You with Elm Street`}
              height="120px"
              width="120px"
              layout="responsive"
              unoptimized={true}
              style={{ borderRadius: " 50%" }}
            />
            <br /> {/* unoptimized={true} */}
            <div style={{ marginTop: "-3px" }}>
              <strong>
                MLS DATA
                <br />
                ASSOCIATIONS
              </strong>
            </div>
            Improve your operations with timely &amp; streamlined MLS data. Reach your target market with seamlessly
            integrated MLS listings.
            <div style={{ paddingTop: "12px" }}>
              <Button variant="contained" color="secondary" href="/mls-data-associations">
                explore
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>

      <div
        style={{
          backgroundImage: "url('horizonbg.webp')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          display: "block",
        }}
      >
        <Container maxWidth="lg" style={{ paddingTop: "80px", paddingBottom: "85px" }}>
          <div style={{ paddingBottom: "50px", textAlign: "center" }}>
            <Typography variant="h5" component="h2" style={{ color: "#ffffff" }}>
              the company <span style={{ color: mainTheme.palette.secondary.main }}>featured in...</span>
            </Typography>
          </div>

          <Grid container columnSpacing={16}>
            <Grid item md={3} sm={12} style={{ paddingBottom: "24px", textAlign: "center" }}>
              <div>
                <Image src="/inman.png" width="150px" height="46px" alt="Elm Street has beeen featured in Inman" />
              </div>
            </Grid>
            <Grid
              item
              md={3}
              sm={12}
              style={{
                paddingBottom: "24px",
                textAlign: "center",
                paddingTop: "12px",
              }}
            >
              <div>
                <Image
                  src="/rismedia.png"
                  width="252px"
                  height="46px"
                  alt="Elm Street has beeen featured in RisMedia"
                />
              </div>
            </Grid>
            <Grid item md={3} sm={12} style={{ paddingBottom: "24px", textAlign: "center" }}>
              <div>
                <Image
                  src="/retech.png"
                  width="185px"
                  height="46px"
                  alt="Elm Street has beeen featured in ReTechnology"
                />
              </div>
            </Grid>
            <Grid
              item
              md={3}
              sm={12}
              style={{
                paddingBottom: "24px",
                textAlign: "center",
                paddingTop: "8px",
              }}
            >
              <div>
                <Image src="/close.png" width="166px" height="46px" alt="Elm Street has beeen featured in The Close" />
              </div>
            </Grid>
          </Grid>

          <div style={{ paddingTop: "30px", textAlign: "center" }}>
            <span style={{ color: "#ffffff" }}>
              “Elm Street's social media marketing has all the hallmarks
              <br />
              of a blockbuster, shout-it-from the rooftops tool.”
              <br />
              <br />
              <strong style={{ color: "#f69c26" }}>Chris Linsell, Writer / Coach</strong>
              <br />
              TheClose.com
            </span>
          </div>
        </Container>
      </div>

      <Container maxWidth="lg" style={{ paddingTop: "80px", paddingBottom: "85px" }}>
        <Typography variant="h5" component="h2">
          Feed Your <span style={{ color: mainTheme.palette.secondary.main }}>Mind</span>
        </Typography>
        <div style={{ marginBottom: "12px" }}>
          <Link
            href={`/blog`}
            style={{
              fontSize: "12px",
              color: "#5BC1BA",
              textDecoration: "none",
            }}
          >
            <strong>&gt; Browse the Elm Street Blog</strong>
          </Link>
        </div>
        <BlogLatest posts={posts} />
      </Container>

      <div
        style={{
          backgroundImage: "url('orangebg.webp')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          display: "block",
        }}
      >
        <Container maxWidth="lg" style={{ paddingTop: "80px", paddingBottom: "85px" }}>
          <div style={{ textAlign: "center" }}>
            <Typography variant="h4" component="h2" style={{ color: "#1d1746" }}>
              grow your business with the perfect products,
              <br />
              services &amp; technology partner.
            </Typography>
            <strong style={{ color: "#1d1746" }}>Success starts with a simple conversation.</strong>
            <br />
            <br />
            <Button variant="contained" color="info" style={{ color: "#1d1746" }} href="/demo">
              schedule a demo
            </Button>
            <br />
            <br />
            <strong style={{ color: "#1d1746" }}>Because you &amp; your business deserve it.</strong>
          </div>
        </Container>
      </div>

      <MainFooter />
    </ThemeProvider>
  );
}

export async function getServerSideProps({ req, res }) {
  let cookies = getCookie("elmlead", { req, res });

  const postQuery = `
    *[_type == "post" ] | order(publishedAt desc) [0...3]{
      _id,
      title,
      slug,
      mainImage {
        asset -> {
          _id,
          url
        }
      },
      publishedAt,
      body,
      thumbnail,
      categories[]->{
        title
      }
    }`;

  const allPosts = await client.fetch(postQuery);

  const eventQuery = `
  *[_type == "event" &&  !("Training" in event_category[]->title)] | order(event_date asc) {
    _id,
    title,
    event_date,
    event_type,
    event_category[]->{
      title
    },
    duration,
    description,
    thumbnail,
    reg_url,
    tags[]->{
      tag
    }
  }
  `;

  const events = await client.fetch(eventQuery);

  if (cookies) {
    return { props: { utm: cookies, posts: allPosts, events } };
  }
  return { props: { posts: allPosts, events } };
}
