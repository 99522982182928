import Image from 'next/image';
import { Box, Typography } from '@mui/material';
import mainTheme from "./mainTheme";
import { ThemeProvider } from '@emotion/react';
const Headshot = ({ src, name, title, alt }) => {
  return (
    <ThemeProvider theme={mainTheme}>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: '100%', height: 0, paddingBottom: '100%', position: 'relative', borderRadius: '50%', overflow: 'hidden' }}>
            <Image src={src} alt={alt} layout="fill" objectFit="cover" />
        </Box>
        <Typography variant="body1" component="p" color={mainTheme.palette.secondary.main} sx={{ mt: 2, fontWeight: 600 }}>
            {name}
        </Typography>
        <Typography variant="body2" textAlign="center" color={mainTheme.typography.body1.color}>
            {title}
        </Typography>
        </Box>
    </ThemeProvider>
  );
};

export default Headshot;
