import { createTheme } from "@mui/material/styles";

const mainTheme = createTheme({
  palette: {
    primary: {
      main: "#50579B",
    },
    secondary: {
      main: "#F69C26",
      dark: "#e3851c"
    },
    info: {
      light: "#FFFFFF",
      main: "#FFFFFF"
    },
    navigation: {
      main: "#333666",
      dark: "#1D1746",
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    letterSpacing: "0.15em",
    body1: {
      fontWeight: 400,
      color: "#333333",
      fontFamily: "'Inter', sans-serif  !important",
      fontStyle: "normal",
    },
    h1: {
      fontWeight: 700,
      textTransform: "uppercase",
      letterSpacing: "0.15em",
      fontSize: "48px",
    },
    h2: {
      fontWeight: 700,
      textTransform: "uppercase",
      letterSpacing: "0.15em",
      fontSize: "36px",
      '@media (max-width:600px)':{
        fontSize: "32px",
      }
    },
    h3: {
      fontWeight: 700,
      textTransform: "uppercase",
      letterSpacing: "0.15em",
      fontSize: "30px"
    },
    h4: {
      fontWeight: 700,
      textTransform: "uppercase",
      letterSpacing: "0.15em",
      fontSize: "24px"
    },
    h5: {
      fontWeight: 700,
      textTransform: "uppercase",
      letterSpacing: "0.15em",
      fontSize: "18px"
    },
  },
  custom: {
    navigationTop: {
      backgroundColor: "#1D1746",
      color: "#FFFFFF",
    },
    navigationBottom: {
      backgroundColor: "#333666",
      color: "#FFFFFF",
    },
    footer: {
      backgroundColor: "#333666",
      color: "#FFFFFF",
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: '14px',
          fontWeight: "700",
          letterSpacing: "0.15em",
          paddingTop: "6px",
          paddingBottom: "6px",
          paddingRight: "24px",
          paddingLeft: "24px",
          borderRadius: 2,    
        },
      },
    },
    MuiPaginationItem:{
      styleOverrides: {
        root:{
          display: 'flex',
          justifyContent: 'center',
          textAlign: "center"
        },
        text: {
          color: "white"
        },
      }
    }
  },
});

export default mainTheme;
