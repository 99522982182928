import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import imageUrlBuilder from '@sanity/image-url'
import { getImageDimensions } from '@sanity/asset-utils'
import Link from "@mui/material/Link";
import Image from "next/image";
import { Grid } from "@mui/material";

import Label from "./Label";
import mainTheme from './mainTheme';
import styles from '../styles/Home.module.css';

import * as dayjs from 'dayjs'
import { truncate } from 'lodash';

export default function EventRow({ event, client, type }) {
    let excerptText = '';
    for(let piece of event.description){
        if(piece.children && piece.children[0].text){
            excerptText += piece.children[0].text + "\n ";
        }
    }
    let finalExcerpt = truncate(excerptText, {
        'length': 80,
        'separator': ' '
        });
    let thumbnailSrc = "/defaultBlogThumb.png"
    if(event.thumbnail){
        const {width, height} = getImageDimensions(event.thumbnail)
        thumbnailSrc = imageUrlBuilder(client).image(event.thumbnail).width(800).fit('max').auto('format').url();
    }

    const thisDate = dayjs(event.event_date).format('MMM D, YYYY') != "Invalid Date" ? dayjs(event.event_date).format('MMM D, YYYY') : "Multiple Date/Times";

    return (
        <ThemeProvider theme={mainTheme}>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={6} md={4} key={event._id} style={{marginBottom:"16px"}}>
                    <div style={{width:"100%",height: "90px",position:"relative",marginBottom:"8px", backgroundColor:"#CCC"}} key={event._id}>
                        <Link href={`${event.reg_url}`}>
                        <img
                        src={thumbnailSrc}
                        alt={event.title || ' '}
                        loading="lazy"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            overflow: "hidden"
                        }}
                        />
                        </Link>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={8} key={event._id} style={{marginBottom:"16px"}}>
                    <div style={{marginBottom:"4px"}}>
                        <Link href={`${event.reg_url}`} style={{fontSize: "18px",textDecoration:"none", fontWeight: "700", color: mainTheme.palette.secondary.main}}>{event.title}</Link>
                    </div>
                    {type != 'ondemand' && <div style={{fontSize:"12px",textTransform:"uppercase"}}>
                    <Image src="/icon_clock.svg" width="20px" height="20px" alt="Clock icon for date"/><div style={{top:"-4px",display:"inline-block",position:"relative",marginLeft:"8px",fontSize:"14px"}}>{thisDate}</div></div>}
                    <div style={{fontSize:"12px",marginBottom:"18px"}}>{finalExcerpt}</div>
                </Grid>
            </Grid>
        </ThemeProvider>
  )
}