import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import imageUrlBuilder from '@sanity/image-url'
import { getImageDimensions } from '@sanity/asset-utils'
import Link from "@mui/material/Link";

import Label from "./Label";
import mainTheme from './mainTheme';
import styles from '../styles/Home.module.css';

import * as dayjs from 'dayjs'
import { truncate } from 'lodash';

export default function BlogThumb({ post, client }) {
    let excerptText = '';
    for(let piece of post.body){
        if(piece.children){
            for(let child of piece.children){
                if(child.text){
                     excerptText += child.text + " ";
                }
            }
            
        }
    }
    let finalExcerpt = truncate(excerptText, {
        'length': 180,
        'separator': ' '
        });
    
    let thumbnailSrc = "/defaultBlogThumb.png"
    if(post.thumbnail){
        const {width, height} = getImageDimensions(post.thumbnail)
        thumbnailSrc = imageUrlBuilder(client).image(post.thumbnail).width(800).fit('max').auto('format').url();
    }

    return (
        <ThemeProvider theme={mainTheme}>
            <div style={{width:"100%",height: "200px",position:"relative",marginBottom:"18px", backgroundColor:"#CCC"}} key={post._id}>
                <Link href={`/blog/${post.slug.current}`}>
                <img
                src={thumbnailSrc}
                alt={post.title || ' '}
                loading="lazy"
                style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    overflow: "hidden"
                }}
                />
                </Link>
            </div>
            <div>{post.categories && post.categories.map(function(cat){
                return <Label key={cat.title}>{cat.title}</Label>;
            })}</div>
            <div style={{fontSize:"12px",textTransform:"uppercase"}} suppressHydrationWarning={true}>{dayjs(post.publishedAt).format('MMM D, YYYY')}</div>
            <div style={{marginTop:"12px",marginBottom:"12px"}} suppressHydrationWarning={true}>
                <Link href={`/blog/${post.slug.current}`} style={{fontSize: "18px",textDecoration:"none", fontWeight: "700", color: mainTheme.palette.secondary.dark}}>{post.title}</Link>
            </div>
            <div style={{fontSize:"14px"}} suppressHydrationWarning={true}>{finalExcerpt}</div>
        </ThemeProvider>
  )
}