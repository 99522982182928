import { useEffect } from 'react'
import { createClient } from "next-sanity";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import BlogThumb from './BlogThumb';

import { getCookie } from 'cookies-next';

import fonts from "../styles/FontInter.module.css";
import mainTheme from './mainTheme';
import styles from '../styles/Home.module.css';

import * as dayjs from 'dayjs'

import client from './client';

export default function BlogLatest({ posts }) {
  return (
    <Box sx={{ flexGrow: 1 }}>
        <Grid container columnSpacing={8}>
        {posts && posts.map(function(post){
            return <Grid item xs={12} sm={6} md={4} key={`grid${post._id}`}>
            <BlogThumb post={post} client={client} key={post._id}/>
            </Grid>
        })}
        </Grid>
    </Box>
  )
}


  